import {
  BorderColor,
  DownloadForOffline,
  FilterList,
  FirstPage,
  LastPage,
} from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment";
import React from "react";
import {
  GetNextOpsExpensesDetails,
  PostAddOperationLevelExpenseCost,
  PutStopOrEditNextDueDateExpense,
  PutUpdateOperationLevelExpenseCost,
} from "../Redux/Actions/adminOperationLevelCostAction";
import { useDispatch, useSelector } from "react-redux";
import {
  ADMIN_ROLES,
  expense_type,
  operation_type,
} from "../../Components/AppMeta/appMetaConfig";
import VehicleAddOperatingLevelCostDialog from "./VehicleAddOperatingLevelCostDialog";
import { GetSupplierDetailsList } from "../Redux/Actions/supplierVehiclesActions";
import { toast } from "react-toastify";
import WarehouseAddDialog from "./WarehouseAddDialog";
import WFSAddCostDialog from "./WFSAddCostDialog";
import CommonOperationLevelCostFilterDialog from "./CommonOperationLevelCostFilterDialog";
import { CSVLink } from "react-csv";

function DueDatesMain() {
  const dispatch = useDispatch();
  const supplierDeliveries = useSelector((state) => state.supplierDeliveries);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [nextDueDatesList, setNextDueDatesList] = React.useState([]);
  const [openAddWFSDetailsDialog, setOpenAddWFSDetailsDialog] =
    React.useState(false);
  const [filterProps, setFilterProps] = React.useState({});
  const [editNextDueDate, setEditNextDueDate] = React.useState("");
  const [openAddVehicleCostDialog, setOpenAddVehicleCostDialog] =
    React.useState(false);
  const [openWarehouseAddDialog, setOpenWarehouseAddDialog] =
    React.useState(false);
  const [filterExpenseType, setFilterExpenseType] = React.useState("");
  const [openCommonFilterDialog, setOpenCommonFilterDialog] =
    React.useState(false);
  const [singleOperationCostDetails, setSingleOperationCostDetails] =
    React.useState({});
  const [openEditSingleOperationCost, setOpenEditSingleOperationCost] =
    React.useState(false);
  const [csvFileData, setCsvFileData] = React.useState([]);
  React.useEffect(() => {
    dispatch(
      GetNextOpsExpensesDetails({
        page_number: pageNumber,
        page_size: pageSize,
      })
    ).then((resp) => {
      if (resp) {
        setNextDueDatesList(resp.data);
      }
    });
  }, [pageSize, pageNumber]);
  const expenseTypeFun = (expenses_type) => {
    switch (expenses_type) {
      case expense_type.RENT:
        return "RENT";
      case expense_type.WFI:
        return "WI-FI";
      case expense_type.TRAFFIC_CHALLAN:
        return "TRAFFIC_CHALLAN";
      case expense_type.INSURANCE:
        return "INSURANCE";
      case expense_type.POLLUTION_CERTIFICATE:
        return "POLLUTION_CERTIFICATE";
      case expense_type.REPAIRS_DAMAGE:
        return "REPAIRS_DAMAGE";
      case expense_type.MAINTENANCE:
        return "MAINTENANCE";
      case expense_type.MONTHLY_SALARY:
        return "MONTHLY_SALARY";
      case expense_type.DAILY_WAGES:
        return "DAILY_WAGES";
      case expense_type.WEEKLY_SALARY:
        return "WEEKLY_SALARY";
      case expense_type.ADVANCED_SALARY:
        return "ADVANCED_SALARY";
      case expense_type.BONUS:
        return "BONUS";
      case expense_type.EMI:
        return "EMI";
      case expense_type.ELECTRICITY:
        return "ELECTRICITY";
      case expense_type.CONSUMABLES:
        return "CONSUMABLES";
      case expense_type.ALLOWANCES:
        return "ALLOWANCES";
      case expense_type.OTHERS:
        return "OTHERS";
      default:
        return "--";
    }
  };

  const monthNames = (month) => {
    switch (month) {
      case 1:
        return "JAN";
      case 2:
        return "FEB";
      case 3:
        return "MARCH";
      case 4:
        return "APRIL";
      case 5:
        return "MAY";
      case 6:
        return "JUNE";
      case 7:
        return "JULY";
      case 8:
        return "AUG";
      case 9:
        return "SEP";
      case 10:
        return "OCT";
      case 11:
        return "NOV";
      case 12:
        return "DESC";
      default:
        return "";
    }
  };

  const csvLink = {
    filename:
      "DeliveryReport" +
      moment().tz("Asia/kolkata").format("YYYY-MM-DD HH:mm:ss") +
      ".csv",
    data: csvFileData,
  };
  function toCSV(expenseDetails) {
    const result = [];
    if (expenseDetails && expenseDetails.length) {
      for (const data of expenseDetails) {
        result.push({
          // warehouse_Name: data.warehouse_details?.contact_name,
          // warehouse_address:
          //   data.warehouse_details?.address_line1 +
          //   "\n" +
          //   data.warehouse_details?.address_line2 +
          //   "\n" +
          //   data.warehouse_details?.address_line3 +
          //   "\n" +
          //   data.warehouse_details?.city +
          //   "\n" +
          //   data.warehouse_details?.pincode +
          //   "\n",
          Operation_type:
            data.ops_type === 100
              ? "WAREHOUSE"
              : data.ops_type === 200
              ? "WFS"
              : data.ops_type === 300
              ? "VEHICLE"
              : data.ops_type === 400
              ? "EMPLOYEE"
              : "--",
          Name: data.details?.tanker_id
            ? data.details?.tanker_id
            : data.warehouse_details?.contact_name
            ? data.warehouse_details?.contact_name
            : data.wfs_details?.company_name
            ? data.wfs_details?.company_name
            : data.employee_details?.driver_profile?.fullname
            ? data.employee_details?.driver_profile?.fullname
            : "",
          Expenses_Type: expenseTypeFun(data.expense_type),
          Month: monthNames(data.details?.month || "--"),
          Paid_date: moment(data.paid_date)
            .tz("Asia/Kolkata")
            .format("DD-MM-YYYY"),
          Amount: data.amount || "--",
          Due_date: moment(data.next_due_date)
            .tz("Asia/Kolkata")
            .format("DD-MM-YYYY"),
          // Reason: data.details?.reason || "--",
          created_at: data.created_at,
          updated_at: data.updated_at,
          created_by:
            data.created_by?.role_id === ADMIN_ROLES.BW_ADMIN
              ? data.created_by?.name
              : data.created_by?.role_id === ADMIN_ROLES.BW_SUPERADMIN
              ? data.created_by?.name
              : data.created_by?.role_id === ADMIN_ROLES.BW_FINANCE
              ? data.created_by?.name
              : data.created_by?.role_id === ADMIN_ROLES.BW_FINANCEHEAD
              ? data.created_by?.name
              : "OTHERS",
          updated_by:
            data.updated_by?.role_id === ADMIN_ROLES.BW_ADMIN
              ? data.updated_by?.name
              : data.updated_by?.role_id === ADMIN_ROLES.BW_SUPERADMIN
              ? data.updated_by?.name
              : data.updated_by?.role_id === ADMIN_ROLES.BW_FINANCE
              ? data.updated_by?.name
              : data.updated_by?.role_id === ADMIN_ROLES.BW_FINANCEHEAD
              ? data.updated_by?.name
              : "OTHERS",
        });
      }
    }
    setCsvFileData(result);
  }

  const handlepayCost = (data, edit) => {
    console.log(data);
    console.log(edit);
    if (data.details?.tanker_id) {
      setOpenAddVehicleCostDialog(true);
      setSingleOperationCostDetails(data);
      setEditNextDueDate(edit);
      setOpenEditSingleOperationCost(true);
      dispatch(GetSupplierDetailsList());
    } else if (data.details.warehouse_id) {
      setOpenWarehouseAddDialog(true);
      setSingleOperationCostDetails(data);
      setEditNextDueDate(edit);
      setOpenEditSingleOperationCost(true);
      dispatch(GetSupplierDetailsList());
    } else if (data.details.wfs_id) {
      setOpenAddWFSDetailsDialog(true);
      setSingleOperationCostDetails(data);
      setEditNextDueDate(edit);
      setOpenEditSingleOperationCost(true);
      dispatch(GetSupplierDetailsList());
    }
  };

  const handleSubmitVehicleDialog = (props) => {
    console.log(props);

    if (
      props.selectedExpenseType !== "" &&
      props.paidDate !== "" &&
      props.amount !== "" &&
      props.selectedWarehouse !== ""
    ) {
      console.log("---------Add------------------");

      if (props.edt_next_due_date === "edit") {
        console.log("------stop-----------");
        const reqObj = {
          reason: props.purpose,
          start_date: props.startDate || undefined,
          month: props.selectMonth || undefined,
          quantity: props.quantity || undefined,
          tanker_id: props.tanker_id || undefined,
          warehouse_id: props.selectedWarehouse || undefined,
          wfs_id: props.selectedWFS || undefined,
          operation_type: props.ops_type,
          expense_type: props.selectedExpenseType,
          date: props.paidDate,
          due_date: props.nextDueDate,
          amount: parseInt(props.amount),
          payment_receipt: props.reciptUrl || undefined,
          account_number: props.accountNumber || undefined,
          is_active: props.stop_expense,
        };
        dispatch(PutStopOrEditNextDueDateExpense(reqObj));
        setOpenAddVehicleCostDialog(false);
        setOpenAddWFSDetailsDialog(false);
        setOpenWarehouseAddDialog(false);
      } else {
        const reqObj = {
          reason: props.purpose,
          start_date: props.startDate || undefined,
          month: props.selectMonth || undefined,
          quantity: props.quantity || undefined,
          tanker_id: props.tanker_id || undefined,
          warehouse_id: props.selectedWarehouse || undefined,
          wfs_id: props.selectedWFS || undefined,
          operation_type: props.ops_type,
          expense_type: props.selectedExpenseType,
          date: props.paidDate,
          due_date: props.nextDueDate || undefined,
          amount: parseInt(props.amount),
          payment_receipt: props.reciptUrl || undefined,
          account_number: props.accountNumber || undefined,
        };
        dispatch(PostAddOperationLevelExpenseCost(reqObj));
        setOpenAddVehicleCostDialog(false);
        setOpenAddWFSDetailsDialog(false);
        setOpenWarehouseAddDialog(false);
      }

      setTimeout(() => {
        dispatch(GetNextOpsExpensesDetails()).then((resp) => {
          if (resp) {
            setNextDueDatesList(resp.data);
          }
        });
      }, 1000);
    } else {
      toast.error("Please Fill The  Fields", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  const handleCloseVehicleDialog = () => {
    setOpenAddVehicleCostDialog(false);
    setOpenWarehouseAddDialog(false);
    setOpenAddWFSDetailsDialog(false);
  };
  const handleCommonFilterDialog = (props) => {
    setFilterProps(props);
    dispatch(
      GetNextOpsExpensesDetails({
        page_number: pageNumber,
        page_size: pageSize,
        ops_type: operation_type.WAREHOUSE,
        expense_type: props.selectedExpenseType || undefined,
        amount: props.amount || undefined,
        date: props.paidDate || undefined,
        due_date: props.nextDueDate || undefined,
        month: props.selectMonth || undefined,
        supplier_id: props.selectedSupplierId || undefined,
        warehouse_id: props.selectedWarehouse || undefined,
      })
    ).then((data) => {
      if (data) {
        setNextDueDatesList(data.data);
      } else {
        console.log("data", data);
        setNextDueDatesList([]);
      }
    });
    setOpenCommonFilterDialog(false);
  };
  const handleCloseCommonFilterDialog = () => {
    setOpenCommonFilterDialog(false);
  };

  const handleOpenCommonFilter = (props) => {
    setFilterExpenseType(props);
    setOpenCommonFilterDialog(true);
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          mb: "10px",
          gap: "10px",
          flexWrap: "wrap",
        }}
      >
        <Button
          variant="contained"
          onClick={() => handleOpenCommonFilter("Duedates")}
        >
          <FilterList />
          Filter
        </Button>
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "column",
              lg: "column",
            },
            alignItems: "center",
          }}
        >
          <InputLabel sx={{ fontSize: "12px", fontWeight: "bold" }}>
            Page Size:
          </InputLabel>
          <Select
            variant="standard"
            sx={{ fontSize: "12px", fontWeight: "bold", width: "80px" }}
            value={pageSize}
            label="Page Size"
            onChange={(event) => setPageSize(event.target.value)}
          >
            <MenuItem value="10">10</MenuItem>
            <MenuItem value="25">25</MenuItem>
            <MenuItem value="50">50</MenuItem>
            <MenuItem value="100">100</MenuItem>
            <MenuItem value="200">200</MenuItem>
            <MenuItem value="300">500</MenuItem>
          </Select>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "5px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <IconButton
            onClick={() => {
              if (pageNumber - 1 > 0) {
                setPageNumber(() => pageNumber - 1);
              }
            }}
          >
            <FirstPage sx={{ color: pageNumber - 1 > 0 ? "blue" : "gray" }} />
          </IconButton>
          <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
            {pageNumber}
          </Typography>
          <IconButton
            onClick={() => {
              if (nextDueDatesList.length === pageSize && pageNumber + 1 > 0) {
                setPageNumber(() => pageNumber + 1);
              }
            }}
          >
            <LastPage
              sx={{
                color: nextDueDatesList.length === pageSize ? "blue" : "gray",
              }}
            />
          </IconButton>
        </Box>
        <Box sx={{ mt: "5px" }}>
          <CSVLink {...csvLink}>
            <DownloadForOffline
              onClick={() => toCSV(nextDueDatesList)}
              sx={{ fontSize: 33 }}
            />
          </CSVLink>
        </Box>
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: "#f4ecf7" }}>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  border: "1.4px solid #ecf0f1",
                  textAlign: "center",
                }}
              >
                S.no
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  border: "1.4px solid #ecf0f1",
                  textAlign: "center",
                }}
              >
                Operation Type
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  border: "1.4px solid #ecf0f1",
                  textAlign: "center",
                }}
              >
                Name
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  border: "1.4px solid #ecf0f1",
                  textAlign: "center",
                }}
              >
                Expense Type
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  border: "1.4px solid #ecf0f1",
                  textAlign: "center",
                }}
              >
                Paid Date
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  border: "1.4px solid #ecf0f1",
                  textAlign: "center",
                }}
              >
                Amount
              </TableCell>

              <TableCell
                sx={{
                  fontWeight: "bold",
                  border: "1.4px solid #ecf0f1",
                  textAlign: "center",
                }}
              >
                Next Due Date
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  border: "1.4px solid #ecf0f1",
                  textAlign: "center",
                }}
              >
                Reason
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  border: "1.4px solid #ecf0f1",
                  textAlign: "center",
                }}
              >
                Account Number
              </TableCell>

              <TableCell
                sx={{
                  fontWeight: "bold",
                  border: "1.4px solid #ecf0f1",
                  textAlign: "center",
                }}
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {nextDueDatesList !== undefined &&
            nextDueDatesList !== null &&
            nextDueDatesList.length > 0 ? (
              <>
                {nextDueDatesList.map((data, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell
                        sx={{
                          border: "1.4px solid #ecf0f1",
                          textAlign: "center",
                        }}
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "1.4px solid #ecf0f1",
                          textAlign: "center",
                        }}
                      >
                        {data.ops_type === 100
                          ? "WAREHOUSE"
                          : data.ops_type === 200
                          ? "WFS"
                          : data.ops_type === 300
                          ? "VEHICLE"
                          : data.ops_type === 400
                          ? "EMPLOYEE"
                          : "--"}
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "1.4px solid #ecf0f1",
                          textAlign: "center",
                        }}
                      >
                        {data.details?.tanker_id
                          ? data.details?.tanker_id
                          : data.warehouse_details?.contact_name
                          ? data.warehouse_details?.contact_name
                          : data.wfs_details?.company_name
                          ? data.wfs_details?.company_name
                          : data.employee_details?.driver_profile?.fullname
                          ? data.employee_details?.driver_profile?.fullname
                          : ""}
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "1.4px solid #ecf0f1",
                          textAlign: "center",
                        }}
                      >
                        {expenseTypeFun(data.expense_type)}
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "1.4px solid #ecf0f1",
                          textAlign: "center",
                        }}
                      >
                        {moment(data.paid_date).format("DD.MM.YYYY")}
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "1.4px solid #ecf0f1",
                          textAlign: "center",
                        }}
                      >
                        {data.amount}
                      </TableCell>

                      <TableCell
                        sx={{
                          border: "1.4px solid #ecf0f1",
                          textAlign: "center",
                        }}
                      >
                        {moment(data.next_due_date)
                          .tz("Asia/Kolkata")
                          .format("DD.MM.YYYY")}
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "1.4px solid #ecf0f1",
                          textAlign: "center",
                        }}
                      >
                        {data.details?.reason ? data.details?.reason : "--"}
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "1.4px solid #ecf0f1",
                          textAlign: "center",
                        }}
                      >
                        {data.details?.account_number
                          ? data.details?.account_number
                          : "--"}
                      </TableCell>

                      <TableCell
                        sx={{
                          border: "1.4px solid #ecf0f1",
                          textAlign: "center",
                        }}
                      >
                        <Button
                          variant="contained"
                          size="small"
                          onClick={() => handlepayCost(data)}
                          sx={{ height: "20px" }}
                        >
                          pay
                        </Button>
                        <br />
                        <Button
                          variant="contained"
                          size="small"
                          sx={{ m: "5px", height: "20px" }}
                          onClick={() => handlepayCost(data, "edit")}
                        >
                          <BorderColor sx={{ width: "20px", height: "14px" }} />
                          Edit
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </>
            ) : (
              <TableRow>
                <TableCell colSpan={14} align="center">
                  <Typography sx={{ color: "red" }}>No Data Found</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {openAddVehicleCostDialog && (
        <VehicleAddOperatingLevelCostDialog
          openAddVehicleCostDialog={openAddVehicleCostDialog}
          handleCloseVehicleDialog={handleCloseVehicleDialog}
          handleSubmitVehicleDialog={handleSubmitVehicleDialog}
          openEditSingleOperationCost={openEditSingleOperationCost}
          singleOperationCostDetails={singleOperationCostDetails}
          supplierDeliveries={supplierDeliveries}
          editNextDueDate={editNextDueDate}
        />
      )}
      {openWarehouseAddDialog && (
        <WarehouseAddDialog
          openWarehouseAddDialog={openWarehouseAddDialog}
          handleCloseWarehouse={handleCloseVehicleDialog}
          handleSubmitWarehouse={handleSubmitVehicleDialog}
          openEditSingleOperationCost={openEditSingleOperationCost}
          singleOperationCostDetails={singleOperationCostDetails}
          supplierDeliveries={supplierDeliveries}
          editNextDueDate={editNextDueDate}
        />
      )}
      {openAddVehicleCostDialog && (
        <VehicleAddOperatingLevelCostDialog
          openAddVehicleCostDialog={openAddVehicleCostDialog}
          handleCloseVehicleDialog={handleCloseVehicleDialog}
          handleSubmitVehicleDialog={handleSubmitVehicleDialog}
          openEditSingleOperationCost={openEditSingleOperationCost}
          singleOperationCostDetails={singleOperationCostDetails}
          supplierDeliveries={supplierDeliveries}
          editNextDueDate={editNextDueDate}
        />
      )}
      {openAddWFSDetailsDialog && (
        <WFSAddCostDialog
          openAddWFSDetailsDialog={openAddWFSDetailsDialog}
          handleCloseAddWFSDialog={handleCloseVehicleDialog}
          handleSubmitAddWFSDialog={handleSubmitVehicleDialog}
          singleOperationCostDetails={singleOperationCostDetails}
          openEditSingleOperationCost={openEditSingleOperationCost}
          editNextDueDate={editNextDueDate}
        />
      )}
      <CommonOperationLevelCostFilterDialog
        openCommonFilterDialog={openCommonFilterDialog}
        handleCloseCommonFilterDialog={handleCloseCommonFilterDialog}
        handleCommonFilterDialog={handleCommonFilterDialog}
        filterExpenseType={filterExpenseType}
        supplierDeliveries={supplierDeliveries}
      />
    </Box>
  );
}

export default DueDatesMain;

import {
  Button,
  Dialog,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
  Typography,
  Input,
  InputAdornment,
  TextField,
  DialogTitle,
  Divider,
} from "@mui/material";
import React from "react";
import OrderButtonSku from "../../Components/Buttons/orderButton";
import { GetSupplierDetailsList } from "../../Pages/Redux/Actions/supplierVehiclesActions";
import { useDispatch } from "react-redux";
import {
  EditBuyBackOrderDetails,
  GenerateCreditNote,
  GetBuyBackOrderDetails,
} from "../../Pages/Redux/Actions/buyBackOrdersAction";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import ConformationDialog from "./conformationDialog";

function GenerateCreditNoteDialog({ open, setOpen, editData, filterObj }) {
  const dispatch = useDispatch();
  const [editDetails, setEditDetails] = React.useState({
    order_id: "",
    invoice_date: moment().format("YYYY-MM-DD"),
    address_id: 0,
    final_price: 0,
    products: [],
  });

  const [selectedSupplier, setSelectedSupplier] = React.useState("");
  const [supplierDetails, setSupplierDetails] = React.useState([]);
  const [confirmDialog, setConfirmDialog] = React.useState(false);
  const [orderDate, setOrderDate] = React.useState(
    moment().format("YYYY-MM-DD")
  );
  //useeffect to get supplier details
  React.useEffect(() => {
    dispatch(GetSupplierDetailsList()).then((data) => {
      setSupplierDetails(data?.data);
    });
  }, []);

  // Function to handle price change
  const handlePriceChange = (product, value) => {
    const regex = /^\d{0,3}$/;
    if (regex.test(value)) {
      const newPrice = parseFloat(value);
      if (newPrice >= 0) {
        setEditDetails((prevDetails) => ({
          ...prevDetails,
          products: prevDetails.products.map((data) =>
            data.container_capacity === product.container_capacity &&
            data.container_material === product.container_material
              ? { ...data, price: newPrice }
              : data
          ),
        }));
      } else {
        setEditDetails((prevDetails) => ({
          ...prevDetails,
          products: prevDetails.products.map((data) =>
            data.container_capacity === product.container_capacity &&
            data.container_material === product.container_material
              ? { ...data, price: "" }
              : data
          ),
        }));
      }
    }
  };

  React.useEffect(() => {
    setEditDetails({
      ...editDetails,
      products: editData?.order_details?.products,
      order_id: editData?.order_details?.order_id,
      address_id: editData?.order_details?.address_id,
    });
  }, [editData]);

  //function to close dialog
  const handelClose = () => {
    setOpen(false);
  };

  //function to handel supplier change
  const onChangeStartDate = (newValue) => {
    if (newValue != null && newValue.$d != null) {
      setOrderDate(() => moment(newValue.$d).tz("Asia/kolkata").format());
      setEditDetails({
        ...editDetails,
        invoice_date: moment(newValue.$d)
          .tz("Asia/kolkata")
          .format("DD/MM/YYYY"),
      });
    }
  };

  //function to handel submit
  const handleSubmit = () => {
    dispatch(GenerateCreditNote(editDetails)).then((data) => {
      if (!data.error) {
        setOpen(false);
        dispatch(GetBuyBackOrderDetails(filterObj));
      }
    });
    console.log("editDetails............");
    console.log(editDetails);
  };

  console.log("editDetails............");
  const handelProceedCancelBatch = () => {
    handleSubmit();
  };
  return (
    <>
      <Dialog open={open}>
        <DialogTitle>
          <Typography sx={{ fontSize: "1.2rem", fontWeight: "bold" }}>
            Credit Note Generation
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
            <Typography sx={{ fontWeight: "600", fontSize: "1rem" }}>
              Enter Per Product Price Details
            </Typography>
          </Box>
          <div style={{ marginBottom: ".5rem" }}></div>
          {editDetails?.products?.map((data) => (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: ".5rem",
              }}
              key={data?.container_capacity}
            >
              <Typography sx={{ textAlign: "start" }}>
                {`${data?.container_capacity}/${data?.container_material} (Qty=${data?.received_qty})`}{" "}
                =
              </Typography>
              <FormControl
                fullWidth
                sx={{ width: "5rem", marginLeft: ".5rem" }}
                variant="standard"
                size="small"
              >
                <Input
                  id="standard-adornment-amount"
                  value={data.price}
                  onChange={(e) => handlePriceChange(data, e.target.value)}
                  startAdornment={
                    <InputAdornment position="start">₹</InputAdornment>
                  }
                />
              </FormControl>
            </Box>
          ))}
          <Divider />
          <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
            <Typography sx={{ fontWeight: "600", fontSize: "1rem" }}>
              Price Details
            </Typography>
          </Box>
          <div style={{ marginBottom: ".5rem" }}></div>
          {editDetails?.products?.map((data) => (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: ".5rem",
              }}
              key={data?.container_capacity}
            >
              <Typography sx={{ textAlign: "start" }}>
                {`${data?.container_capacity}/${data?.container_material} (${data?.received_qty} x ${data?.price}) `}{" "}
                =
              </Typography>
              <Typography sx={{ textAlign: "start" }}>
                ₹ {`${data?.received_qty * data?.price}`}
              </Typography>
            </Box>
          ))}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              alignItems: "center",
              marginTop: "1rem",
              fontWeight: "bold",
            }}
          >
            <Typography sx={{ textAlign: "start" }}>Total:</Typography>
            <Typography sx={{ textAlign: "start" }}>
              ₹{" "}
              {editDetails?.products?.reduce(
                (total, data) => total + data?.received_qty * data?.price,
                0
              )}
            </Typography>
          </Box>
          <Divider />
          <div style={{ marginBottom: "1rem" }}></div>
          <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
            <Typography sx={{ fontWeight: "600", fontSize: "1rem" }}>
              Select Credit Note Date
            </Typography>
          </Box>
          <div style={{ marginBottom: ".5rem" }}></div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label="Credit Note Date"
              inputFormat="DD/MM/YYYY"
              // minDate={moment().subtract(0, 'days')}
              value={orderDate}
              onChange={onChangeStartDate}
              disablePast
              renderInput={(params) => (
                <TextField
                  size="small"
                  {...params}
                  required
                  inputProps={{
                    ...params.inputProps,
                    readOnly: true,
                  }}
                />
              )}
            />
          </LocalizationProvider>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: 3,
            }}
          >
            {" "}
            <Button
              size="small"
              variant="contained"
              color="error"
              onClick={handelClose}
            >
              Close
            </Button>
            <Button
              size="small"
              variant="contained"
              onClick={() => setConfirmDialog(true)}
            >
              submit
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      <ConformationDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        dialogMsg="Are you certain that the price details are accurate? An email will be sent to the user."
        handelProceed={handelProceedCancelBatch}
      />
    </>
  );
}

export default GenerateCreditNoteDialog;

import React, { Fragment, useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RegularOrdersTable from "../../Pages/AdminOrders/RegularOrdersTable";
import { Collapse } from "@mui/material";
import TableRows from "./custom/TableRows";
const CustomDataGrid = ({ columns, rows, isProducts, formattedProducts }) => {
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up("sm"));

  // Return early if there are no columns or rows
  if (!columns || columns.length === 0 || !rows || rows.length === 0) {
    return <Box>No data available</Box>;
  }

  return (
    <Box sx={{ width: "100%", overflowX: "auto" }}>
      <TableContainer
        component={Paper}
        sx={{
          border: "1px solid #ddd",
          borderRadius: "8px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Table
          sx={{
            minWidth: 650,
            "& .MuiTableCell-root": {
              borderBottom: "1px solid #ccc", // Row borders
            },
          }}
          aria-label="custom data table"
        >
          {/* Table Head */}
          <TableHead>
            <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
              {columns.map((column) => (
                <TableCell
                  key={column.field}
                  sx={{
                    fontWeight: "bold",
                    fontSize: isMobile ? "12px" : "16px",
                    borderRight: "1px solid #ddd", // Column borders
                    padding: isMobile ? "8px" : "16px",
                  }}
                  align="center"
                >
                  {column.headerName}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          {/* Table Body */}
          <TableBody>
            {rows.map((row) => {
              return (
                <TableRows columns={columns} row={row} isProduct={isProducts} />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default CustomDataGrid;

import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { Fragment, useState } from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export default function TableRows({ row, columns, isProduct }) {
  const [open, setOpen] = useState(false);
  const isMobile = false;
  return (
    <Fragment>
      <TableRow
        key={row.id}
        sx={{
          "&:nth-of-type(odd)": { backgroundColor: "#fafafa" }, // Zebra stripes
          "&:hover": { backgroundColor: "#e0f7fa" }, // Hover effect
        }}
      >
        {/* First Column with Accordion */}
        <TableCell
          sx={{
            borderRight: "1px solid #ddd", // Column borders
            fontSize: isMobile ? "12px" : "14px",
            padding: isMobile ? "8px" : "16px",
          }}
          align="center"
        >
          <TableCell sx={{ display: "flex" }}>
            {row[columns[0]?.field]}
            {isProduct ? (
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            ) : (
              <></>
            )}
          </TableCell>
        </TableCell>

        {/* Other columns */}
        {columns.slice(1).map((column) => (
          <TableCell
            key={column.field}
            sx={{
              borderRight: "1px solid #ddd", // Column borders
              fontSize: isMobile ? "12px" : "14px",
              padding: isMobile ? "8px" : "16px",
            }}
            align="center"
          >
            {row[column.field] !== null && row[column.field] !== undefined
              ? row[column.field].toString()
              : "N/A"}
          </TableCell>
        ))}
      </TableRow>
      {isProduct ? (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  Products
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      {!row?.packaged_drinking_water?.damage_invoice
                        ?.length && (
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Damaged Qty
                        </TableCell>
                      )}
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Capacity
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Material
                      </TableCell>
                      <TableCell align="center" sx={{ fontWeight: "bold" }}>
                        Order Quantity(packs)
                      </TableCell>
                      <TableCell align="center" sx={{ fontWeight: "bold" }}>
                        Return Quantity(units)
                      </TableCell>
                      <TableCell align="center" sx={{ fontWeight: "bold" }}>
                        SKU Size
                      </TableCell>
                      <TableCell align="center" sx={{ fontWeight: "bold" }}>
                        Scanned Empty
                      </TableCell>
                      <TableCell align="center" sx={{ fontWeight: "bold" }}>
                        Scanned Filled
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!row?.packaged_drinking_water?.is_invalid ? (
                      <TableRow key={100}>
                        {!row?.packaged_drinking_water?.damage_invoice
                          ?.length && (
                          <TableCell component="th" scope="row">
                            {row?.packaged_drinking_water?.damage_invoice
                              ?.length
                              ? "--"
                              : row?.packaged_drinking_water
                                  ?.consumer_damaged_qty || 0}
                          </TableCell>
                        )}
                        <TableCell component="th" scope="row">
                          {
                            row?.packaged_drinking_water
                              ?.selected_container_capacity
                          }
                        </TableCell>
                        <TableCell align="center">
                          {
                            row.packaged_drinking_water
                              ?.selected_container_material
                          }
                        </TableCell>
                        <TableCell align="center">
                          {row?.packaged_drinking_water?.twentyltr_capacity_qty}
                        </TableCell>
                        <TableCell align="center">
                          {row?.packaged_drinking_water?.empty_container_qty}
                        </TableCell>
                        <TableCell align="center">1</TableCell>
                        <TableCell align="center">
                          {row?.packaged_drinking_water?.received_empty_cans}
                        </TableCell>
                        <TableCell align="center">
                          {row?.packaged_drinking_water?.submitted_filled_cans}
                        </TableCell>
                      </TableRow>
                    ) : null}
                    {row?.products?.length &&
                      row?.products?.map((product, key) => (
                        <TableRow key={key}>
                          {!row?.packaged_drinking_water?.damage_invoice
                            ?.length && (
                            <TableCell component="th" scope="row">
                              {product?.consumer_damaged_qty || "--"}
                            </TableCell>
                          )}
                          <TableCell component="th" scope="row">
                            {product?.capacity}
                          </TableCell>
                          <TableCell align="center">
                            {product?.material}
                          </TableCell>
                          <TableCell align="center">
                            {" "}
                            {product?.quantity}
                            <br />({product?.quantity} * Pack Of{" "}
                            {product?.sku_qty}={" "}
                            {product?.quantity * product.sku_qty})
                          </TableCell>
                          <TableCell align="center">
                            {product?.return}
                          </TableCell>
                          <TableCell align="center">
                            {product?.sku_qty}
                          </TableCell>
                          <TableCell align="center">
                            {product?.consumer_submitted_empty}
                          </TableCell>
                          <TableCell align="center">
                            {product?.consumer_received_filled}
                          </TableCell>
                        </TableRow>
                      ))}
                    {row?.packaged_drinking_water?.damage_invoice?.map(
                      (product, key) => (
                        <TableRow key={key}>
                          <TableCell component="th" scope="row">
                            Consumer Damaged Product
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {product?.capacity}
                          </TableCell>
                          <TableCell align="center">
                            {product?.material}
                          </TableCell>
                          <TableCell align="center">
                            {" "}
                            {product?.quantity}
                            <br />({product?.quantity} * Pack Of{" "}
                            {product?.sku_qty}={" "}
                            {product?.quantity * product?.sku_qty})
                          </TableCell>
                          <TableCell align="center">0</TableCell>
                          <TableCell align="center">
                            {product?.sku_qty}
                          </TableCell>
                          <TableCell align="center">0</TableCell>
                          <TableCell align="center">0</TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      ) : (
        <></>
      )}
    </Fragment>
  );
}

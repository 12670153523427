import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  NativeSelect,
  TextField,
  Typography,
  Popover,
  MenuItem,
  Select,
  Chip,
  IconButton,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { FirstPage, LastPage, Search } from "@mui/icons-material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ReportsTable from "../../Components/AdminReports/ReportsTable";
import {
  GetAdminReports,
  GetReport,
} from "../Redux/Actions/adminReportsAction";
import * as XLSX from "xlsx";
import { useDispatch, useSelector } from "react-redux";
// import DateRangePicker from "./DateRangePicker";
import DownloadIcon from "@mui/icons-material/Download";
import { toast } from "react-toastify";
import { formatAndMergeProducts } from "../../Components/AdminReports/TransformReportsData";

const Adminreports = () => {
  const dispatch = useDispatch();
  const reportsList = useSelector((state) => state.adminReport.reports);

  const [selectedReportId, setSelectedReportId] = useState();

  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [reportData, setReportData] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [filteredReportData, setFilteredReportData] = useState([]);
  const [formattedProducts, setFormattedProducts] = useState([]);
  const [selectedSortColumn, setSelectedSortColumn] = useState("");
  const [selectedSortOrder, setSelectedSortOrder] = useState("ASC");

  const handleChangePageSize = (e) => {
    let newSize = e.target.value;
    if (newSize > 1000) {
      newSize = 1000; // Restrict page size to 1000
      toast.warning("Page size cannot exceed 1000.");
    }

    setPageSize(Number(newSize) || "");
    if (newSize && newSize >= 0) {
      setPageNumber(0);
    }
  };

  const updateTotalPages = (newTotalPages) => {
    setTotalPages(newTotalPages);
  };

  // Handle Popover for Filters
  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const openFilterPopover = Boolean(anchorEl);
  const filterPopoverId = openFilterPopover ? "filter-popover" : undefined;

  const handleFilterSelect = (event) => {
    setSelectedFilter(event.target.value);
    const currentIndex = selectedFilters?.findIndex(
      (item) => item.key === event.target.value
    );
    if (currentIndex >= 0) {
      setFilterValue(selectedFilters[currentIndex].value); // Reset value when filter changes
    } else {
      setFilterValue("");
    } // Reset value when filter changes
  };
  const handleClearFilters = () => {
    setSelectedFilters([]);
    setSelectedFilter("");
    setFilterValue("");
  };
  const filterReportData = (data) => {
    return data.map((item) => {
      // If `is_products` is true, remove both the key and the value for "packaged_drinking_water" and "products"
      const { packaged_drinking_water, products, ...rest } = item;
      // products +PDW
      return rest;
    });
  };

  const handleApplyFilters = () => {
    if (filterValue) {
      const currentIndex = selectedFilters?.findIndex(
        (item) => item.key === selectedFilter
      );

      if (currentIndex >= 0) {
        setSelectedFilters((prev) => {
          const updated = [...prev];
          updated[currentIndex].value = filterValue;
          return updated;
        });
      } else {
        setSelectedFilters((prev) => [
          ...prev,
          { value: filterValue, key: selectedFilter },
        ]);
      }
    }

    console.log("Applied Filters:", selectedFilters);
    setAnchorEl(null);

    // After applying filters, call the GetReport API
    const params = Object.fromEntries(
      selectedFilters.map((filter) => [filter.key, filter.value])
    );
    // Add sorting parameters as sort_by array
    if (selectedSortColumn && selectedSortOrder) {
      params.sort_column = selectedSortColumn;
      params.sort_order = selectedSortOrder;
    }
    Object.assign(params, {
      page_size: pageSize,
      page_number: pageNumber,
    });
    console.log(params, ".........paramsobject..........");

    if (selectedReportId) {
      dispatch(GetReport(selectedReportId, params))
        .then((reportData) => {
          if (reportData?.error) {
            console.error("Error fetching report data:", reportData.msg);
            setFilteredReportData([]); // Set to empty array if there's an error
          } else {
            // console.log("Fetched Report Data:", reportData);
            // setFilteredReportData(reportData?.data || []); // Only set data if available
            let filteredData = reportData?.data || [];
            setFilteredReportData(reportData?.data || []); // Set to empty array if there's an error
            return;
            // Conditionally hide both keys and values if `is_products` is true
            if (selectedReport?.is_products) {
              filteredData = filterReportData(filteredData);
            }

            // Format `packaged_drinking_water` and `products` for the accordion
            const formattedProducts = formatAndMergeProducts(
              reportData?.data || []
            );
            setFormattedProducts(formattedProducts); // Store formatted data for accordion

            setFilteredReportData(filteredData);
          }
        })
        .catch((error) => {
          console.error("Error fetching filtered report data:", error);
          setFilteredReportData([]); // Handle case when API call fails
        });
    }
  };

  const handleDeleteFilter = (index) => {
    setSelectedFilters((prev) => {
      prev.splice(index, 1);
      return JSON.parse(JSON.stringify(prev));
    });
  };

  // const handleFilterInputChange = (event) => {
  //   setFilterValue(event.target.value);
  // };
  const handleFilterInputChange = (
    event,
    rangeType = null,
    filterKey = null
  ) => {
    const value = event.target.value;

    // Debug: log the input event and filterKey
    console.log(
      `Filter key: ${filterKey}, Range type: ${rangeType}, Value: ${value}`
    );

    if (rangeType && filterKey) {
      // Handle date range (updating from_date or to_date)
      setFilterValue((prevValues) => {
        const updatedValue = {
          ...prevValues,
          [filterKey]: {
            ...prevValues[filterKey],
            [rangeType]: value, // Update either 'from' or 'to' date
          },
        };

        // Debug: log updated filterValue for date range
        console.log("Updated filterValue (date_range):", updatedValue);
        return updatedValue;
      });
    } else {
      // Handle normal single-value filters (text, email, etc.)
      setFilterValue(value);

      // Debug: log updated filterValue for single-value filters
      console.log("Updated filterValue (single-value):", value);
    }
  };
  const handleDropDownChange = (e) => {
    const reportId = parseInt(e.target.value);
    setSelectedReportId(reportId);

    setSelectedFilters([]);
    setSelectedFilter("");
    setFilterValue("");

    console.log("Filters cleared for report ID:", reportId);
  };

  const selectedReport = reportsList?.find(
    (report) => report.id === selectedReportId
  );

  const handleDownloadXLSX = async () => {
    if (!filteredReportData || filteredReportData.length === 0) {
      toast.error("No data available to download", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }

    let combinedReportData = [];
    let uniqueKeys = new Set();

    if (selectedReport?.is_products) {
      // If is_products is true, merge formatted product details into the report data
      filteredReportData.forEach((reportItem) => {
        const baseReportItem = { ...reportItem };

        const formattedProductData = formatAndMergeProducts(reportItem);
        // Combine base report with formatted product data
        const mergedReportItem = { ...baseReportItem, ...formattedProductData };
        delete mergedReportItem.packaged_drinking_water;
        delete mergedReportItem.products;
        uniqueKeys = new Set([
          // ...Object.keys(formattedProductData),
          ...Object.keys(mergedReportItem),
          ...uniqueKeys,
        ]);
        // Ensure any empty fields are set to 0
        Object.keys(mergedReportItem).forEach((key) => {
          if (
            mergedReportItem[key] === undefined ||
            mergedReportItem[key] === null ||
            mergedReportItem[key] === ""
          ) {
            mergedReportItem[key] = 0; // Set empty fields to 0
          }
        });
        // Exclude columns related to packaged_drinking_water and products if is_products is true
        // const filteredMergedReportItem = Object.keys(mergedReportItem).reduce(
        //   (acc, key) => {
        //     if (
        //       !key.includes("packaged_drinking_water") &&
        //       !key.includes("products")
        //     ) {
        //       acc[key] = mergedReportItem[key];
        //     }
        //     return acc;
        //   },
        //   {}
        // );

        // console.log("Filtered Merged Report Item:", filteredMergedReportItem);
        combinedReportData.push(mergedReportItem);
      });
      combinedReportData = await combinedReportData.map((item) => {
        for (const key of uniqueKeys) {
          if (item[key] === undefined) {
            item[key] = 0;
          }
        }
        return item;
      });

      console.log(uniqueKeys);
    } else {
      // If is_products is false, just use the filteredReportData without modifications
      combinedReportData = filteredReportData.map((reportItem) => {
        // Ensure any empty fields are set to 0
        Object.keys(uniqueKeys).forEach((key) => {
          if (
            reportItem[key] === undefined ||
            reportItem[key] === null ||
            reportItem[key] === ""
          ) {
            reportItem[key] = 0; // Set empty fields to 0
          }
        });
        return reportItem;
      });
    }

    console.log("Combined Data for Download:", combinedReportData);

    // Create a new workbook
    const newWorkbook = XLSX.utils.book_new();
    const newWorksheet = XLSX.utils.json_to_sheet(combinedReportData);
    XLSX.utils.book_append_sheet(newWorkbook, newWorksheet, "Report");
    XLSX.writeFile(
      newWorkbook,
      `Report_${new Date().toLocaleDateString()}.xlsx`
    );
  };

  useEffect(() => {
    dispatch(GetAdminReports());
  }, [dispatch]);

  useEffect(() => {
    handleApplyFilters();
  }, [pageNumber]);

  useEffect(() => {
    setSelectedReportId(reportsList?.[0]?.id);
  }, [reportsList]);

  useEffect(() => {
    if (selectedReportId) {
      setFilteredReportData([]);
      setFormattedProducts([]);
      setSelectedFilters([]);
      setSelectedSortColumn("");
      setSelectedSortOrder("ASC");
    }
  }, [selectedReportId]);
  useEffect(() => {
    setTimeout(() => {
      if (!selectedFilter) {
        return;
      }
      setSelectedFilters((prev) => {
        const currentIndex = prev?.findIndex(
          (item) => item.key === selectedFilter
        );
        if ((filterValue === "" || filterValue === null) && currentIndex >= 0) {
          const updated = [...prev];
          updated.splice(currentIndex, 1);
          return updated;
        } else if (currentIndex >= 0) {
          const updated = [...prev];
          updated[currentIndex].value = filterValue;
          return updated;
        } else {
          if (filterValue === "" || filterValue === null) {
            return prev;
          }
          return [...prev, { key: selectedFilter, value: filterValue }];
        }
      });
    }, 300);
  }, [filterValue, selectedFilter]);

  useEffect(() => {
    console.log(
      "Filters applied:wtsdgtsd..........................",
      selectedFilters
    );
  }, [selectedFilters]);

  useEffect(() => {
    console.log("Report Data:", reportData);
  }, [reportData]);

  useEffect(() => {
    console.log("Report Data:", reportData);
  }, [reportData]);
  useEffect(() => {
    console.log("Filtered Data", filteredReportData);
  }, [filteredReportData]);
  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", marginTop: "4em" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <FormControl key={selectedReportId} size="medium">
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Reports
              </InputLabel>
              <NativeSelect
                value={selectedReportId}
                key={selectedReportId}
                onChange={(e) => handleDropDownChange(e)}
                // defaultValue={1}
                inputProps={{ name: "Reports", id: "uncontrolled-native" }}
              >
                {reportsList?.map((item, index) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              marginTop: "5px",
            }}
          >
            {/* Filter Button */}
            <Button variant="contained" onClick={handleFilterClick}>
              Filter
            </Button>
            {/* <Box> */}{" "}
            {/* <DownloadIcon
              sx={{ fontSize: 35 }}
              // onClick={() => orderToCSV(filteredReportData)}
            /> */}
            <DownloadIcon
              sx={{ fontSize: 35, cursor: "pointer" }} // Add cursor pointer for better UX
              onClick={handleDownloadXLSX} // Handle the XLSX download on click
            />
            {/* </Box> */}
          </Box>

          {/* Popover for Filter */}
          <Popover
            id={filterPopoverId}
            open={openFilterPopover}
            anchorEl={anchorEl}
            onClose={handleFilterClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Box sx={{ p: 2, width: 300 }}>
              <Box>
                {selectedFilters?.map((item, index) => {
                  return (
                    <Chip
                      sx={{ marginBottom: "6px" }}
                      label={
                        <>
                          {item.key} : {item.value}
                        </>
                      }
                      color="warning"
                      onDelete={() => handleDeleteFilter(index)}
                    />
                  );
                })}
              </Box>
              {/* Sorting Controls */}
              <Box sx={{ display: "flex", gap: 1, mt: 2 }}>
                {/* Sort Column Dropdown */}
                <FormControl fullWidth>
                  <InputLabel>Sort Column</InputLabel>
                  <Select
                    label="Sort Column"
                    value={selectedSortColumn}
                    onChange={(e) => setSelectedSortColumn(e.target.value)}
                  >
                    <MenuItem value="">Select Column</MenuItem>
                    {selectedReport?.sortColumns?.map((col) => (
                      <MenuItem key={col.key} value={col.key}>
                        {col.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {/* Sort Order Dropdown */}
                <FormControl fullWidth>
                  <InputLabel>Sort Order</InputLabel>
                  <Select
                    label="Sort Order"
                    value={selectedSortOrder}
                    onChange={(e) => setSelectedSortOrder(e.target.value)}
                  >
                    {/* <MenuItem value="">Select Order</MenuItem> */}
                    {selectedReport?.sortOrder?.map((order) => (
                      <MenuItem key={order} value={order}>
                        {order}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              <FormControl fullWidth sx={{ marginInline: "2px", mt: 1 }}>
                <InputLabel>Select Filter</InputLabel>
                <Select
                  label="Select Filter"
                  value={selectedFilter}
                  onChange={handleFilterSelect}
                >
                  {selectedReport?.filters?.map((filter) => (
                    <MenuItem key={filter.key} value={filter.key}>
                      {filter.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* Render input based on filter type */}
              {selectedFilter && (
                <Box sx={{ mt: 2 }}>
                  {selectedReport?.filters
                    ?.filter((filter) => filter.key === selectedFilter)
                    ?.map((filter) => {
                      switch (filter.type) {
                        case "text":
                          return (
                            <TextField
                              key={filter.key}
                              fullWidth
                              label={filter.name}
                              value={filterValue}
                              onChange={handleFilterInputChange}
                            />
                          );
                        case "email": // Handle email input type
                          return (
                            <TextField
                              key={filter.key}
                              fullWidth
                              label={filter.name}
                              type="email"
                              value={filterValue}
                              onChange={handleFilterInputChange}
                            />
                          );
                        case "date":
                          return (
                            <TextField
                              key={filter.key}
                              fullWidth
                              type="date"
                              label={filter.name}
                              InputLabelProps={{ shrink: true }}
                              value={filterValue}
                              onChange={handleFilterInputChange}
                            />
                          );
                        case "boolean":
                          return (
                            <FormControl fullWidth key={filter.key}>
                              <InputLabel>{filter.name}</InputLabel>
                              <Select
                                value={filterValue}
                                onChange={handleFilterInputChange}
                              >
                                <MenuItem value="true">Yes</MenuItem>
                                <MenuItem value="false">No</MenuItem>
                              </Select>
                            </FormControl>
                          );
                        case "number":
                          return (
                            <TextField
                              key={filter.key}
                              fullWidth
                              type="number"
                              label={filter.name}
                              value={filterValue}
                              onChange={handleFilterInputChange}
                            />
                          );
                        case "phone": // New case for phone
                          return (
                            <TextField
                              key={filter.key}
                              fullWidth
                              label={filter.name}
                              type="tel"
                              value={filterValue}
                              onChange={handleFilterInputChange}
                              inputProps={{
                                pattern: "[0-9]{3}-[0-9]{3}-[0-9]{4}", // Example phone pattern
                                placeholder: "1234567890",
                              }}
                            />
                          );
                        case "radio":
                          return (
                            <FormControl component="fieldset" key={filter.key}>
                              <FormLabel component="legend">
                                {filter.name}
                              </FormLabel>
                              <RadioGroup
                                row
                                value={filterValue}
                                onChange={handleFilterInputChange}
                              >
                                {filter.values.map((option) => (
                                  <FormControlLabel
                                    key={option.key}
                                    value={option.key}
                                    control={<Radio />}
                                    label={option.name}
                                  />
                                ))}
                              </RadioGroup>
                            </FormControl>
                          );
                        case "date_range":
                          return <Box key={filter.key}></Box>;
                        case "enum": // Handling enum type dynamically
                          return (
                            <FormControl fullWidth key={filter.key}>
                              <InputLabel>{filter.name}</InputLabel>
                              <Select
                                value={filterValue}
                                onChange={handleFilterInputChange}
                              >
                                {filter.values?.map((option) => (
                                  <MenuItem key={option} value={option}>
                                    {option}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          );
                        default:
                          return null;
                      }
                    })}
                </Box>
              )}
              {/* Action Buttons */}
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
              >
                <Button variant="outlined" onClick={handleClearFilters}>
                  Clear
                </Button>
                <Button variant="contained" onClick={handleApplyFilters}>
                  Apply
                </Button>
              </Box>
            </Box>
          </Popover>
        </Box>

        {/* Pagination Controls */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            flexWrap: "wrap",
            mt: 2,
          }}
        >
          <Box sx={{ maxWidth: "120px", mt: 2 }}>
            <TextField
              size="small"
              value={pageSize}
              label="Page size"
              onChange={handleChangePageSize}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search
                      cursor="pointer"
                      size="30px"
                      onClick={handleApplyFilters}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          {/* <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "5px",
              marginTop: "10px",
            }}
          >
            <Button onClick={pageChangeBackward} disabled={pageNumber === 1}>
              <ArrowBackIosIcon />
            </Button>

            <Typography
              variant="button"
              display="block"
              gutterBottom
              sx={{
                backgroundColor: "#2D81FF",
                color: "white",
                padding: "5px",
                width: "40px",
                height: "35px",
                borderRadius: "15px",
                textAlign: "center",
              }}
            >
              {pageNumber}
            </Typography>

            <Button
              onClick={pageChangeForward}
              disabled={pageNumber >= totalPages}
            >
              <ArrowForwardIosIcon />
            </Button>
          </Box> */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "5px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconButton
              onClick={() => {
                if (pageNumber > 0) {
                  setPageNumber(() => pageNumber - 1);
                }
              }}
            >
              <FirstPage sx={{ color: pageNumber > 0 ? "blue" : "gray" }} />
            </IconButton>
            <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
              {pageNumber + 1}
            </Typography>
            <IconButton
              onClick={() => {
                if (pageNumber >= 0 && filteredReportData.length === pageSize) {
                  setPageNumber(() => pageNumber + 1);
                }
              }}
            >
              <LastPage
                sx={{
                  color:
                    filteredReportData.length === pageSize ? "blue" : "gray",
                }}
              />
            </IconButton>
          </Box>
        </Box>

        {/* Report Table */}
        <ReportsTable
          reportData={filteredReportData}
          pageSize={pageSize}
          pageNumber={pageNumber}
          updateTotalPages={updateTotalPages}
          isProducts={selectedReport?.is_products}
          formattedProducts={formattedProducts}
          selectedReportId={selectedReportId}
        />
      </Box>
    </>
  );
};

export default Adminreports;

import useAxios from "../../Axios/useAxios";
import actionType from "./actionType";
import { toast } from "react-toastify";
import CustomConsole from "../../../CustomConsole";

const httpStatus = {
  SUCCESS: 200,
  BAD_REQUEST: 400,
};
//function to create buy back order creation
export function CreateBuyBackOrder(object) {
  let api = useAxios();
  return async () => {
    try {
      let response = await api.post(
        "/OM/operations/createorder/buybackproduct",
        object
      );
      console.log(response);
      if (response.status === httpStatus.SUCCESS) {
        toast.dark(response.data.msg, {
          position: "bottom-right",
          autoClose: 3000,
          type: "success",
        });
        return response.data;
      } else {
        toast.dark(response?.data?.msg, {
          position: "bottom-right",
          autoClose: 3000,
          type: "error",
        });
        return response.data;
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.msg || " Unable to create an order!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return false;
    }
  };
}

//function to get buy back products

export const GetBuyBackOrderDetails = (props) => {
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.get(`/OM/operations/details/buybackproduct`, {
        params: props,
      });
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_BUYBACK_ORDER_DETAILS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: actionType.GET_BUYBACK_ORDER_DETAILS,
          payload: response.data,
        });
        toast.error(
          response?.data?.data?.msg || "Unable to get Order Details",
          {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
      }
    } catch (error) {
      dispatch({
        type: actionType.GET_BUYBACK_ORDER_DETAILS,
        payload: error?.response?.data,
      });
      let r = error?.response?.data?.msg;
      toast.error(error?.response.data?.msg || " Unable to Get Order Details", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
};

//function to cancel buy back order creation
export function CancelBuyBackOrder(object) {
  let api = useAxios();
  return async () => {
    try {
      let response = await api.put(
        "/OM/operations/cancelorder/buybackproduct",
        object
      );
      console.log(response);
      if (response.status === httpStatus.SUCCESS) {
        toast.dark(response.data.msg, {
          position: "bottom-right",
          autoClose: 3000,
          type: "success",
        });
        return response.data;
      } else {
        toast.dark(response.data.msg, {
          position: "bottom-right",
          autoClose: 3000,
          type: "error",
        });
        return response.data;
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response.data?.msg || " Unable to create an order!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return false;
    }
  };
}

//function to edit buy back order details
export function EditBuyBackOrderDetails(object) {
  let api = useAxios();
  return async () => {
    try {
      let response = await api.put(
        "/OM/operations/editorder/buybackproduct",
        object
      );
      console.log(response);
      if (response.status === httpStatus.SUCCESS) {
        toast.dark(response.data.msg, {
          position: "bottom-right",
          autoClose: 3000,
          type: "success",
        });
        return response.data;
      } else {
        toast.dark(response.data.msg, {
          position: "bottom-right",
          autoClose: 3000,
          type: "error",
        });
        return response.data;
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response.data?.msg || " Unable to edit an order!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return true;
    }
  };
}

//function to get buy back products

export const CompleteBuyBackOrderDetails = (object) => {
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.put(
        `/OM/operations/completeorder/buybackproduct`,
        object
      );
      if (response.status === httpStatus.SUCCESS) {
        toast.success(response.data?.msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        return response.data;
      } else {
        toast.error(response.data?.msg || "Unable to Complete Order", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        return response.data;
      }
    } catch (error) {
      let r = error?.response.data?.msg;
      toast.error(error?.response.data?.msg || " Unable to Complete Order", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return { error: true };
    }
  };
};

//function to complete buy back order details
export function GenerateCreditNote(object) {
  let api = useAxios();
  return async () => {
    try {
      let response = await api.post(
        "/SSM/generatecredit/buybackproduct",
        object
      );
      console.log(response);
      if (response.status === httpStatus.SUCCESS) {
        toast.dark(response.data.msg, {
          position: "bottom-right",
          autoClose: 3000,
          type: "success",
        });
        return response.data;
      } else {
        toast.dark(response.data.msg, {
          position: "bottom-right",
          autoClose: 3000,
          type: "error",
        });
        return response.data;
      }
    } catch (error) {
      console.log(error);
      toast.error(
        error?.response.data?.msg || " Unable to generate credit note!",
        {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
      return true;
    }
  };
}

//function to get buy back products

export const GetCreditNoteDetails = (props) => {
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.get(`/SSM/details/creditnote`, {
        params: props,
      });
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_CREDIT_NOTE_DETAILS,
          payload: response.data,
        });
        return response.data;
      } else {
        return {
          error: true,
        };
      }
    } catch (error) {}
  };
};

//function to cancel buy back order creation
export function CancelCreditDetails(object) {
  let api = useAxios();
  return async () => {
    try {
      let response = await api.put(
        `/SSM/cancelinvoice/buybackproduct?id=${object}`
      );
      console.log(response);
      if (response.status === httpStatus.SUCCESS) {
        toast.dark(response.data.msg, {
          position: "bottom-right",
          autoClose: 3000,
          type: "success",
        });
        return response.data;
      } else {
        toast.dark(response.data.msg, {
          position: "bottom-right",
          autoClose: 3000,
          type: "error",
        });
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response.data?.msg || " Unable to cancel an credit!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
}

export const DownloadCreditNote = (invoiceId) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };

  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.get(
        `/SSM/downloadinvoice/buybackproduct?id=${invoiceId}`,
        {
          responseType: "blob",
          onDownloadProgress: (progressEvent) => {
            // Calculate and use the progress
            const totalLength = progressEvent.total;
            const loaded = progressEvent.loaded;
            const progress = Math.round((loaded / totalLength) * 100);
            CustomConsole(`Download Progress: ${progress}%`);
            dispatch({
              payload: { error: false },
              type: actionType.INVOICE_ITEMIZED_GENERATION,
              progress: progress,
            });
          },
        }
      );
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.INVOICE_ITEMIZED_GENERATION,
          payload: response.data,
        });
        CustomConsole("--------Response From the Api------");
        CustomConsole(response);
        // Create a Blob from the binary response data
        const blob = new Blob([response.data], { type: "application/pdf" });

        // Create a URL for the Blob
        const url = URL.createObjectURL(blob);

        // Open the PDF in a new window/tab
        window.open(url, "_blank");
        if (response.data.length <= 0) {
          CustomConsole("no available toast");
        }
        toast.dark("Credit Note Generated!", {
          position: "bottom-right",
          autoClose: 3000,
          type: "success",
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
      dispatch({
        type: actionType.INVOICE_GENERATION,
        payload: {
          error: true,
          msg: "Error in generating invoice check your order combinations!..",
        },
      });
      toast.dark(
        "Error in generating invoice check your order combinations!..",
        {
          position: "bottom-right",
          autoClose: 5000,
          type: "error",
        }
      );
    }
  };
};

//function to get buy back products

export const GetSettlementDetails = (props) => {
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.get(`/SSM/details/settlement`, {
        params: props,
      });
      if (response.status === httpStatus.SUCCESS) {
        return response.data;
      } else {
        toast.error(response.data?.data?.msg || "Unable to get  Details", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        return response.data;
      }
    } catch (error) {
      toast.error(error?.response.data?.msg || " Unable to Get  Details", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return error.response.data;
    }
  };
};

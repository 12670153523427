import { useEffect } from "react";
import { useSelector } from "react-redux";
import CustomDataGrid from "../Tables/CustomDataGrid";

// Simplified function to map object keys to columns
const convertKeysToUpper = (obj) => {
  let result = {};
  for (const [key, value] of Object.entries(obj)) {
    // Handle arrays by converting them to JSON strings
    if (Array.isArray(value)) {
      result[key.toUpperCase()] = value.length
        ? JSON.stringify(value, null, 2) // Format array as a JSON string
        : "N/A";
    }
    // Handle objects like `total_hours`
    else if (typeof value === "object" && value !== null) {
      result[key.toUpperCase()] = JSON.stringify(value, null, 2); // Convert object to string
    }
    // Handle primitive values
    else {
      result[key.toUpperCase()] = value == null ? "N/A" : value;
    }
  }
  return result;
};

const ReportsTable = ({
  reportData,
  pageSize,
  pageNumber,
  updateTotalPages,
  isProducts,
  formattedProducts,
  selectedReportId,
}) => {
  const { loading } = useSelector((state) => ({
    loading: state.adminReport.loading,
  }));

  // Debugging Logs
  console.log("Report Data:", reportData);
  console.log("Page Size:", pageSize);
  console.log("Page Number:", pageNumber);

  useEffect(() => {
    const totalDataCount = reportData?.length || 0;
    const calculatedTotalPages = Math.ceil(totalDataCount / pageSize);
    console.log("Total Data Count:", totalDataCount);
    updateTotalPages(calculatedTotalPages);
  }, [reportData, pageSize, updateTotalPages]);

  // Check if reportData is populated
  if (!reportData || reportData.length === 0) {
    console.log("No report data available!");
    return <div>No data available</div>;
  }

  // Convert keys to uppercase without flattening deeply
  const upperCaseData = reportData;
  const uniqueKeys = Array.from(new Set(upperCaseData.flatMap(Object.keys)));

  // Generate columns dynamically based on the unique keys
  const keys = uniqueKeys.map((key) => ({
    field: key,
    headerName: key.replace(/_/g, " ").toUpperCase(),
    flex: 1,
  }));
  const columns = keys?.filter((key) => {
    if (isProducts) {
      if (
        ["PACKAGED_DRINKING_WATER", "PRODUCTS"].includes(
          key.field?.toUpperCase()
        )
      ) {
        return;
      } else return key;
    } else {
      return key;
    }
  });
  console.log("Columns Generated:", columns);

  // Calculate the start index and slice the data accordingly for pagination
  const startIndex = (pageNumber - 1) * pageSize;

  // Generate rows dynamically based on the paginated data
  const rows = reportData.map((row, index) => ({
    id: startIndex + index + 1, // Ensure unique IDs across pages
    ...row,
  }));

  console.log("Rows Generated:", rows);

  return (
    <>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <CustomDataGrid
          columns={columns}
          rows={rows}
          isProducts={isProducts}
          formattedProducts={formattedProducts}
          pageSize={pageSize}
          pageNumber={pageNumber}
          selectedReportId={selectedReportId}
        />
      )}
    </>
  );
};

export default ReportsTable;

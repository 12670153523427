export const returnCanOrderStatus = {
  CONSUMER_ACCEPTED: 800,
  REFUND: 600,
  CONSUMER_REJECTED: 900,
  DELIVERED: 500,
  REJECTED: 700,
  PENDING: 0,
  APPROVED: 200,
  CANCELLED: 400,
};

export const ordersStatus = {
  ORDER_STATUS_NEW_REQUEST: 100,
  ORDER_STATUS_ACCEPTED: 200,
  ORDER_STATUS_REJECTED: 300,
  ORDER_STATUS_CANCELLED: 400,
  ORDER_STATUS_DELIVERED: 500,
};
export const replacementCanOrdersStatus = {
  CAN_REPLACE_ORDER_REQUESTED: 100,
  CAN_REPLACE_ORDER_ADMIN_ACCEPTED: 200,
  CAN_REPLACE_ORDER_ADMIN_REJECTED: 300,
  CAN_REPLACE_ORDER_STARTED: 400,
  CAN_REPLACE_ORDER_FINISHED: 500,
};

export const deliveryStatus = {
  DELIVERY_STATUS_SCHEDULED: 100,
  DELIVERY_STATUS_STARTED: 200,
  DELIVERY_STATUS_DELIVERED: 300,
  DELIVERY_STATUS_CANCELLED: 400,
};

export const VehicleTypes = [
  { name: "Two Wheeler", value: 4 },
  { name: "Truck", value: 5 },
  { name: "Mini Truck", value: 6 },
  { name: "Carrier", value: 7 },
];
export const ADMIN_ROLES = {
  BW_SUPERADMIN: 1000,
  BW_ADMIN: 1001,
  BW_WFSADMIN: 1002,
  BW_DISTRIBUTER: 2000,
  BW_DELIVERYAGENT: 2001,
  BW_SUPPLIERMANAGER: 2002,
  BW_SUPPLIER_LOADERS: 2003,
  BW_CONSUMER: 3000,
  BW_SALESHEAD: 4000,
  BW_CS: 5001,
  BW_CSHEAD: 5000,
  BW_CSLEAD: 5004,
  BW_SALES: 4001,
  BW_OPS: 6001,
  BW_OPSHEAD: 6000,
  BW_FINANCE: 7001,
  BW_FINANCEHEAD: 7000,
  BW_QA: 8001,
  BW_QAHEAD: 8000,
  BW_PROMOTER: 9999,
  BW_IVML: 6002,
};

export const INFLUENCER_TYPE = {
  USER_TYPE_INFLUENCER: 111,
  USER_TYPE_CONSUMER: 0,
  USER_TYPE_INFLUENCER_REQUESTED: 110,
  USER_TYPE_INFLUENCER_REJECTED: 112,
  USER_TYPE_INFLUENCER_DISABLED: 113,
};

export const offertype = {
  CASHBACK_WATER_DROP: 100,
  PERCENTAGE_DISCOUNT: 200,
  PRICE_DISCOUNT: 300,
};

export const DELIVERY_TYPE = {
  INSTA_DELIVERY: 700,
};

export const ORDER_TYPE = {
  SUBSCRIPTION_ORDER: 100,
  INSTA_DELIVERY_ORDER: 200,
  POST_PAID_ORDER: 300,
  REGULAR_ORDER: 400,
};

export const ORDER_GRAPH = {
  GRAPH_ORDER_ONGOING: 100,
  GRAPH_ORDER_DELIVERED: 200,
  GRAPH_ORDER_CANCELLED: 300,
};
export const ordersStatusWFSCan = {
  EC_ORDER_CONFIRMED: 100,
  EC_ORDER_COMPLETED: 200,
  EC_ORDER_CANCELLED: 300,
  EC_ORDER_PAYMENT_PENDING: 400,
  EC_ORDER_ADMIN_ACCEPTED: 501,
  EC_ORDER_ADMIN_REJECTED: 502,
};
export const soldBySelect = {
  SOLD_BY_BOOKWATER: 1,
  SOLD_BY_DISTRIBUTOR: 2,
};
export const transferCansStatus = {
  TRANSFER_CAN_ORDER_CREATED: 0,
  TRANSFER_CAN_ORDER_CONFIRMED: 200,
  TRANSFER_CAN_ORDER_INSCAN_COMPLETED: 400,
  TRANSFER_CAN_ORDER_REJECTED: 500,
};

export const postpaidOrderStatus = {
  POST_PAID_REQUESTED: 100,
  POST_PAID_ACCEPTED: 200,
  POST_PAID_REJECTED: 300,
  POST_PAID_FORCE_CANCEL: 400,
};

export const postpaidInvoiceStatus = {
  INVOICE_ONGOING: 100,
  INVOICE_SETTLED: 200,
  INVOICE_CANCELLED: 300,
};

export const graphTypes = {
  B2B_B2C_REVENUE: 1,
  B2B_B2C_QUANTITY_REVENUE: 2,
  B2C_QUANTITY_REVENUE: 3,
};

export const paymentBlockingCodes = {
  PAY_SHARP_ALL: 100,
  PAY_SHARP_UPI: 101,
  PAY_SHARP_QR: 102,
  PAY_SHARP_SEARCH_UPI: 103,
  PAY_SHARP_VIRTUAL_BANK: 104,
  RAZOR_PAY_BLOCK: 200,
  WALLET_BLOCK: 300,
};

export const typeOfBlocking = {
  dateRange: 100,
  bookingType: 200,
  pincode: 300,
  areas: 301,
  districts: 302,
  states: 303,
  countries: 304,
};

export const orderType = {
  INSTA: 100,
  POSTPAID: 200,
  SUBSCRIPTION: 300,
  REGULAR: 400,
};

export const employeeType = {
  MANAGER: 1,
  DRIVER: 2,
  LOADER: 3,
};

export const RECYCLE_STATUS_CODE = {
  REQUESTED: 100,
  ACCEPTED: 200,
  STARTED: 201,
  REJECTED: 300,
  CANCELLED: 400,
  FINISHED: 500,
};

export const DAMAGE_REASON_CODES = {
  QrBarcodeDamaged: 104,
  PhysicallyDamaged: 101,
  containerReceived: 1,
  constanerNotReceived: 0,
};
export const operation_type = {
  WAREHOUSE: 100,
  WFS: 200,
  VEHICLE: 300,
  EMPLOYEES: 400,
};
export const expense_type = {
  RENT: 100,
  WFI: 200,
  FUEL: 300,
  ELECTRICITY: 400,
  CONSUMABLES: 500,
  ALLOWANCES: 600,
  TRAFFIC_CHALLAN: 700,
  INSURANCE: 800,
  POLLUTION_CERTIFICATE: 900,
  REPAIRS_DAMAGE: 1000,
  MAINTENANCE: 1001,
  MONTHLY_SALARY: 1002,
  DAILY_WAGES: 1003,
  WEEKLY_SALARY: 1004,
  ADVANCED_SALARY: 1005,
  BONUS: 1006,
  OTHERS: 1007,
  EMI: 1008,
  RECHARGE: 1009,
};

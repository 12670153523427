/**
 * @author Gowtham Prasath
 * @description
 * @copyright Bookwater tech pvt ltd
 * @version 0.0.96
 * @Date 25-01-2024
 */
import React from "react";
import CustomConsole from "../../CustomConsole";
import * as MuiIcons from "@mui/icons-material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import FilterListIcon from "@mui/icons-material/FilterList";
import { appColors } from "../../Components/Assests/AppTheme/appThemeConst";
import moment from "moment";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteLocalityFromPincode,
  SkuAvailablePincodes,
} from "../../Pages/Redux/Actions/skuPincodeAction";
import {
  CancelBuyBackOrder,
  CancelCreditDetails,
  DownloadCreditNote,
  GetBuyBackOrderDetails,
  GetCreditNoteDetails,
} from "../../Pages/Redux/Actions/buyBackOrdersAction";
import EditBuyBackOrderOrderDialog from "../Dialog/editBuyBackOrderDialog";
import BuyBackFilterDialog from "../Dialog/buyBackFilterDialog";
import { ordersStatus } from "../AppMeta/appMetaConfig";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

const DetailsStyles = {
  contentBox: { display: "flex", justifyContent: "space-center" },
};
function CreditNoteDetailsTabel(props) {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState("");
  const [openFlag, setOpenFlag] = React.useState(false);
  const [confirmDialog, setConfirmDialog] = React.useState(false);
  const [editData, setEditData] = React.useState({});
  const [localityId, seLocalityId] = React.useState({
    locality_ids: [],
  });
  const [pageNumber, setPageNumber] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(25);
  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const [openGenerateCredit, setOpenGenerateCredit] = React.useState(false);
  const skuPincodeDetails = useSelector((state) => state.skuPincodeDetails);
  const creditDetails = useSelector(
    (state) => state.buyBackOrderDetails.creditDetails
  );
  const [filterObj, setFilterObj] = React.useState({
    sort_order: "DESC",
    order_status: null,
    page_size: 25,
    page_number: 0,
    phone: null,
  });
  const [openFilterDialog, setOpenFilterDialog] = React.useState(false);
  //function for handling view icon
  const handelIcon = (index) => {
    setOpen(index);
    setOpenFlag(!openFlag);
  };

  //function for handling edit product details
  // const handleFilterChange = (e) => {
  //   CustomConsole(e.target.value);
  //   // props.setQueryParams((prev) => ({ ...prev, page_size: e.target.value }));
  // };
  const handleFilterChange = (event) => {
    setPageSize(event.target.value);
  };

  //function for changing page number
  React.useEffect(() => {
    dispatch(GetCreditNoteDetails(filterObj));
  }, [filterObj]);

  //function for changing page number
  React.useEffect(() => {
    if (pageSize > 25) {
      setFilterObj((prev) => ({
        ...prev,
        page_size: pageSize,
      }));
    }
  }, [pageSize]);

  //function for changing page number
  React.useEffect(() => {
    if (pageNumber > 0) {
      setFilterObj((prev) => ({
        ...prev,
        page_number: pageNumber - 1,
      }));
    }
  }, [pageNumber]);
  //function for storing locality value
  const handleClickDelete = (data) => {
    dispatch(CancelCreditDetails(data)).then((data) => {
      if (!data.error) {
        dispatch(
          GetCreditNoteDetails({
            page_number: pageNumber - 1,
            page_size: pageSize,
          })
        );
      }
    });
  };

  const handelDownLoadCredit = (id) => {
    dispatch(DownloadCreditNote(id));
  };

  //function for deleting locality
  const handelConformDelete = () => {
    dispatch(DeleteLocalityFromPincode(localityId));
  };

  //useEffect for hitting api
  React.useEffect(() => {
    if (!skuPincodeDetails.deleteLocalityError) {
      setConfirmDialog(false);
    }
  }, [skuPincodeDetails.deleteLocalityError]);

  React.useEffect(() => {
    console.log("buyBackOrderDetails...................");
    console.log(creditDetails);
  }, [creditDetails]);

  //function to open edit dialog
  const handleClickEdit = (data) => {
    setEditData(data);
    setOpenEditDialog(true);
  };

  //function to handel filter submit
  const handelFilterSubmit = (data) => {
    setFilterObj((prev) => ({
      ...prev,
      sort_order: data.sortByDate,
      order_status: data.sortByStatus,
      page_size: pageSize,
      page_number: pageNumber - 1,
      phone: data.phoneNumber,
    }));
    setOpenFilterDialog(false);
  };

  const orderStatus = (order_status) => {
    switch (order_status) {
      case 200:
        return "SETTLED";
      case 100:
        return "PENDING";
      case 300:
        return "CANCELLED";
      default:
        return "";
    }
  };
  //function to clear filter
  const handelClearSubmit = () => {
    setPageNumber(1);
    setPageSize(25);
    if (pageNumber === 1 && pageSize === 25) {
      setFilterObj((prev) => ({
        ...prev,
        sort_order: "DESC",
        order_status: null,
        page_size: 25,
        page_number: 0,
        phone: null,
      }));
    }
  };

  return (
    <>
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          boxShadow: 5,
          marginTop: "1rem",
          padding: "1rem",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
          {" "}
          <Button
            onClick={() => setOpenFilterDialog(true)}
            size="small"
            startIcon={<FilterListIcon />}
          >
            Filter
          </Button>
          <FormControl sx={{ minWidth: 100 }} size="small">
            <InputLabel>Page size</InputLabel>
            <Select
              value={pageSize}
              size="small"
              sx={{ height: "2.2rem" }}
              label="Page size"
              onChange={handleFilterChange}
              name="page_size"
            >
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </FormControl>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "5px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconButton
              onClick={() => {
                if (pageNumber - 1 > 0) {
                  setPageNumber(() => pageNumber - 1);
                }
              }}
            >
              <MuiIcons.FirstPage
                sx={{ color: pageNumber - 1 > 0 ? "blue" : "gray" }}
              />
            </IconButton>
            <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
              {pageNumber}
            </Typography>
            <IconButton
              sx={{ maxHeight: "50px" }}
              onClick={() => {
                if (pageSize == creditDetails?.length) {
                  setPageNumber(() => pageNumber + 1);
                }
              }}
            >
              <MuiIcons.LastPage
                sx={{
                  color: pageSize == creditDetails?.length ? "blue" : "gray",
                }}
              />
            </IconButton>
          </Box>
          {/* <CSVLink {...csvLink}>
            <SimCardDownloadRoundedIcon
              // sx={{ ...styles.reportDownloadIcon }}
              onClick={() => toCSV(props.pincodeData)}
            />
          </CSVLink> */}
        </Box>

        <TableContainer sx={{ maxHeight: 440 }}>
          <Table aria-label="collapsible table" stickyHeader>
            <TableHead>
              <TableRow sx={appColors.commonLayoutColor}>
                <TableCell />
                <TableCell size="small" align="start">
                  <strong>S.no</strong>
                </TableCell>
                <TableCell size="small" align="center">
                  <strong>Credit Note Id</strong>
                </TableCell>
                <TableCell size="small" align="center">
                  <strong> Status</strong>
                </TableCell>
                <TableCell size="small" align="center">
                  <strong>Order Id</strong>
                </TableCell>
                <TableCell size="small" align="center">
                  <strong>Credit Amount</strong>
                </TableCell>
                <TableCell size="small" align="center">
                  <strong>Pending Amount</strong>
                </TableCell>
                <TableCell size="small" align="center">
                  <strong>Name</strong>
                </TableCell>
                <TableCell size="small" align="center"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {creditDetails?.map((data, index) => (
                <>
                  <TableRow
                    sx={{ "& > *": { borderBottom: "unset" } }}
                    key={index}
                  >
                    <TableCell>
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => handelIcon(index)}
                      >
                        {openFlag ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </IconButton>
                    </TableCell>
                    <TableCell size="small" align="start">
                      {index + 1}
                    </TableCell>
                    <TableCell size="small" align="center">
                      {data?.credit_id}
                    </TableCell>
                    <TableCell size="small" align="center">
                      {orderStatus(data?.status)}
                    </TableCell>
                    <TableCell size="small" align="center">
                      {data?.order_id}
                    </TableCell>
                    <TableCell size="small" align="center">
                      ₹ {data?.total_amount}
                    </TableCell>
                    <TableCell size="small" align="center">
                      ₹ {data?.pending_amount}
                    </TableCell>
                    <TableCell size="small" align="center">
                      {data?.consumer_profile?.billing_name}
                    </TableCell>
                    <TableCell size="small" align="center">
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          gap: 1,
                        }}
                      >
                        {data?.status === 100 &&
                        data?.pending_amount == data?.total_amount ? (
                          <Button
                            sx={{ height: "25px" }}
                            variant="contained"
                            color="error"
                            onClick={() => handleClickDelete(data?.credit_id)}
                            size="small"
                          >
                            Cancel
                          </Button>
                        ) : (
                          ""
                        )}
                        {data?.status === 100 && (
                          <Button
                            sx={{ height: "25px" }}
                            variant="contained"
                            color="success"
                            onClick={() =>
                              handelDownLoadCredit(data?.credit_id)
                            }
                            size="small"
                          >
                            Download
                          </Button>
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      colSpan={12}
                    >
                      {open === index && openFlag === true ? (
                        <Box sx={{ margin: 1 }}>
                          <Table size="small" aria-label="purchases">
                            <TableHead>
                              <TableRow sx={appColors.commonLayoutColor}>
                                <TableCell
                                  size="small"
                                  sx={{ border: "1px solid black" }}
                                >
                                  <strong>Product</strong>
                                </TableCell>
                                <TableCell
                                  sx={{ border: "1px solid black" }}
                                  size="small"
                                >
                                  <strong>Capacity</strong>
                                </TableCell>
                                <TableCell
                                  sx={{ border: "1px solid black" }}
                                  size="small"
                                  align="center"
                                >
                                  <strong> Quantity</strong>
                                </TableCell>
                                <TableCell
                                  sx={{ border: "1px solid black" }}
                                  size="small"
                                  align="center"
                                >
                                  <strong>Unit Price</strong>
                                </TableCell>
                                <TableCell
                                  sx={{ border: "1px solid black" }}
                                  size="small"
                                  align="center"
                                >
                                  <strong>Total Price</strong>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {data?.products?.map((data) => (
                                <TableRow key={data.container_material}>
                                  <TableCell size="small">
                                    {data?.container_material}
                                  </TableCell>
                                  <TableCell size="small">
                                    {data?.container_capacity}
                                  </TableCell>
                                  <TableCell size="small" align="center">
                                    {data?.received_qty}
                                  </TableCell>
                                  <TableCell size="small" align="center">
                                    ₹ {data?.price}
                                  </TableCell>
                                  <TableCell size="small" align="center">
                                    ₹ {data?.total_price}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </Box>
                      ) : (
                        ""
                      )}
                    </TableCell>
                  </TableRow>
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      {openEditDialog && (
        <EditBuyBackOrderOrderDialog
          open={openEditDialog}
          setOpen={setOpenEditDialog}
          editData={editData}
        />
      )}

      <BuyBackFilterDialog
        open={openFilterDialog}
        setOpen={setOpenFilterDialog}
        handelFilterSubmit={handelFilterSubmit}
        handelClearSubmit={handelClearSubmit}
        type={200}
      />
    </>
  );
}

export default CreditNoteDetailsTabel;

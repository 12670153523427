/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 * @author Gowtham Prasath
 */
import React, { useState, useEffect } from "react";
import axios from "axios";
// import useAxios from "../../Hooks/useAxios";
import useAxios from "../../Pages/Axios/useAxios";
// import { url } from "../../Redux/apis";
import { url } from "../../Pages/Redux/apis";

import CustomConsole from "../../CustomConsole";
import { StoreImageFiles } from "../../Pages/Redux/Actions/fileHandlingAction";
import { useDispatch, useSelector } from "react-redux";
const useProductImages = (products, pageName) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const api = useAxios();
  const dispatch = useDispatch();
  const imageFilesstore = useSelector(
    (state) => state.fileHandler.appImages
  );
  console.log("getFilesDownload........................");
  console.log(imageFilesstore);
  useEffect(() => {
    const fetchImages = async () => {
      try {
        // Use Promise.all to fetch and store images in parallel
        const imagePromises = products.map(async (product) => {
          let filename = "";
          if (pageName !== "deposit") {
            if (product.image !== null) {
              filename = product.image;
            } else {
              return true;
            }
          } else if (pageName === "deposit") {
            if (
              product?.more_details?.image !== undefined &&
              product?.more_details?.image !== null
            ) {
              filename = product?.more_details?.image;
            }
          }

          // Check if the image is already available in local storage
          const storedImage = imageFilesstore[`image_${filename}`];

          if (!storedImage) {
            // Fetch the image from the API
            const imageResponse = await api.get(
              `${url}/SSM/files/${filename}`,
              {
                responseType: "blob", // Use blob to handle binary data
              }
            );

            // Convert the image blob to a base64-encoded string
            const reader = new FileReader();
            reader.readAsDataURL(imageResponse.data);

            // Update the local storage with the base64-encoded string
            reader.onloadend = () => {
              const base64Image = reader.result;
              dispatch(StoreImageFiles(`image_${filename}`, base64Image));
            };

            return { [filename]: null }; // Placeholder until base64 is ready
          }

          return { [filename]: storedImage };
        });

        // Wait for all image promises to resolve
        await Promise.all(imagePromises);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching product images:", error);
        setError(error);
        setIsLoading(false);
      }
    };

    fetchImages();
  }, [products]);

  return { isLoading, error };
};

export default useProductImages;
